/* eslint-disable react/no-array-index-key */
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledIndicationTag from '../../../../components/Common/IndicationTag';
import StyledButton from '../../../../components/Common/StyledButton';
import { addClassification, handleFormatValueFields } from '../../../../helpers/nUtils';
import { VerticalCard } from '../VerticalCard';
import * as S from './styles';
import { ISupplierResponseCard } from './types';

export function SupplierResponseCard(props: ISupplierResponseCard) {
  const { t } = useTranslation();

  return (
    <S.SupplierResponseCard>
      <Flex justify="space-between" align="center" style={{ width: '100%' }}>
        <VerticalCard
          title={t('pages.viewRfq.openCard.supplier')}
          value={props.supplier}
          font="LG"
        />

        <S.Classification>
          {props.position ? addClassification(String(props.position)) : '-'}
        </S.Classification>
      </Flex>
      <Flex vertical style={{ marginTop: '1rem' }}>
        <S.Title>{t('pages.viewRfq.openCard.partNumber')}</S.Title>
        <Flex style={{ minHeight: '1.25rem' }}>
          {props.partNumber ? (
            <Flex align="center">
              <StyledIndicationTag
                type={props.partNumber.qstatus}
                text={props.partNumber.qstatus || ''}
              />
              <S.PartNumber qStatus={props.partNumber.qstatus}>
                {props.partNumber.partNumber}
              </S.PartNumber>
            </Flex>
          ) : (
            <S.PartNumber qStatus="-">-</S.PartNumber>
          )}
        </Flex>
        <S.Divider />
        <Flex gap={24}>
          <VerticalCard
            title={t('pages.viewRfq.openCard.capacity')}
            value={props.capacity || '-'}
            revertRow
            gap={8}
            fontTitle="SM"
          />
          <VerticalCard
            title={t('pages.viewRfq.openCard.leadTime')}
            value={props.leadTime ? `${props.leadTime} ${t('common.days')}` : '-'}
            revertRow
            gap={8}
            fontTitle="SM"
          />
        </Flex>
        <S.Divider />
        <Flex gap={16}>
          <VerticalCard
            title={t('pages.viewRfq.openCard.grossPrice')}
            value={props.grossPrice ? `$ ${handleFormatValueFields(props.grossPrice)}` : '-'}
            revertRow
            gap={8}
            valueBlue={!!props.grossPrice}
            font="SM"
            fontTitle="SM"
          />
          <VerticalCard
            title={t('pages.viewRfq.openCard.lastGross')}
            value={props.lastGross ? `$ ${handleFormatValueFields(props.lastGross)}` : '-'}
            revertRow
            gap={8}
            font="SM"
            fontTitle="SM"
          />
          <Flex gap={8}>
            <VerticalCard
              title={t('pages.viewRfq.openCard.gapGross')}
              value={
                props.gapGross ? (
                  <S.GapGross value={props.grossPrice || 0}>
                    $ {handleFormatValueFields(props.gapGross)}
                  </S.GapGross>
                ) : (
                  '-'
                )
              }
              revertRow
              gap={8}
              font="SM"
              fontTitle="SM"
            />
            <S.Percentage>{props.gapGrossPercentage}</S.Percentage>
          </Flex>
        </Flex>
        <S.Divider />
        <Flex gap={24}>
          <VerticalCard
            title={t('pages.viewRfq.openCard.netPrice')}
            value={props.netPrice ? `$ ${handleFormatValueFields(props.netPrice)}` : '-'}
            revertRow
            gap={8}
            font="LG"
            fontTitle="SM"
          />
          <VerticalCard
            title={t('pages.viewRfq.openCard.totalNet')}
            value={props.totalNet ? `$ ${handleFormatValueFields(props.totalNet)}` : '-'}
            revertRow
            gap={8}
            font="LG"
            fontTitle="SM"
          />
        </Flex>
        {props.showCommentsButton && (
          <StyledButton
            variant="slim"
            text={t('pages.viewRfq.openCard.comments')}
            style={{ padding: '0.25rem', width: 'max-content', marginTop: '1rem' }}
            onClick={props.handleDetailsModal}
          />
        )}
      </Flex>
    </S.SupplierResponseCard>
  );
}
