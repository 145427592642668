import { Form } from 'antd';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleFormatValueFields, oneAlert } from '../../../../helpers/nUtils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IError, IStep1Form } from '../../types';

export function useRegisterBid(bidId?: string) {
  const { t } = useTranslation();
  const [form] = Form.useForm<IStep1Form>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const save = async (values: IStep1Form): Promise<AxiosResponse<IStep1Form> | undefined> => {
    try {
      setIsLoadingSubmit(true);
      const body = {
        ...values,
        priceInterval: Number(values.priceInterval) || null,
        sketch: values.sketch || false,
        startDate: values.period ? dayjs(values.period[0]).format('YYYY/MM/DD HH:mm:ss') : null,
        endDate: values.period ? dayjs(values.period[1]).format('YYYY/MM/DD HH:mm:ss') : null,
      };
      if (bidId) {
        const response = await api.put(`${services.rfq}/bid/registerBid/${bidId}`, { ...body });
        return response;
      }
      const response = await api.post(`${services.rfq}/bid/registerBid/`, { ...body });
      return response;
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnSave'),
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const getInformations = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(`${services.rfq}/bid/registerBid/${bidId}`);
      form.setFieldsValue({
        ...data.step1,
        priceInterval: data.step1.priceInterval
          ? handleFormatValueFields(data.step1.priceInterval, 2, 2)
          : null,
        period: [dayjs(data.step1.startDate), dayjs(data.step1.endDate)],
      });
      return data;
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bidId) {
      getInformations();
    }
  }, [bidId]);

  return {
    form,
    save,
    getInformations,
    isLoading,
    isLoadingSubmit,
  };
}
