import { InfoCircleOutlined } from '@ant-design/icons';

import { DatePicker, Flex, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { LiaDollarSignSolid } from 'react-icons/lia';

import { IStep1 } from './types';

import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import theme from '../../../../styles/theme';
import * as S from '../styles';

const { RangePicker } = DatePicker;

export function RegisterBid({
  form,
  onCancel,
  bidStatus,
  bidId,
  handleSubmit,
  isLoadingSave,
  isLoadingData,
}: IStep1) {
  const { t } = useTranslation();

  const validatePriceInterval = (value: string) => {
    if (!value || /^\d+\.\d{2}$/.test(value)) {
      return true;
    }
    return false;
  };

  const validateBidPeriod = (value: string) => {
    if (dayjs().format() > dayjs(value[0]).format()) {
      return false;
    }
    return true;
  };

  const validateValue = (value: string) => {
    if (!value || /^\d+(\.\d+)?$/.test(value)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <S.ContentForm>
        {isLoadingData ? (
          <StyledLoading height={30} />
        ) : (
          <S.StyledForm layout="vertical" requiredMark form={form}>
            <Form.Item
              label={t('pages.newRfq.steps.registerBid.fields.nameBID')}
              name="nameBid"
              rules={[
                {
                  required: true,
                  message: `${t('pages.newRfq.steps.registerBid.fields.nameBID')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  min: 2,
                  message: t('pages.newRfq.steps.registerBid.fields.nameBIDMinCharacter'),
                },
                {
                  max: 70,
                  message: t('pages.newRfq.steps.registerBid.fields.nameBIDMaxCharacter'),
                },
              ]}
            >
              <Input
                placeholder={t('pages.newRfq.steps.registerBid.fields.placeholderNameBID')}
                minLength={2}
                maxLength={70}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.newRfq.steps.registerBid.fields.description')}
              name="description"
            >
              <Input.TextArea
                placeholder={t('pages.newRfq.steps.registerBid.fields.placeholderDescriptionBID')}
                maxLength={400}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Flex gap={24}>
                <Form.Item
                  label={t('pages.newRfq.steps.registerBid.fields.BIDPeriod')}
                  name="period"
                  rules={[
                    {
                      required: true,
                      message: `${t('pages.newRfq.steps.registerBid.fields.BIDPeriod')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                    {
                      validator: (_, value) =>
                        validateBidPeriod(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              t('pages.newRfq.steps.registerBid.fields.startBIDPeriodError')
                            ),
                    },
                  ]}
                  shouldUpdate
                >
                  <RangePicker
                    disabled={bidStatus === 'STARTED'}
                    showTime
                    placeholder={[
                      t('pages.newRfq.steps.registerBid.fields.startBIDPeriodPlaceholder'),
                      t('pages.newRfq.steps.registerBid.fields.endBIDPeriodPlaceholder'),
                    ]}
                  />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {() => {
                    return (
                      <Form.Item
                        label={t('pages.newRfq.steps.registerBid.fields.priceInterval')}
                        name="priceInterval"
                        rules={[
                          { required: false },
                          {
                            validator: (_, value) =>
                              validatePriceInterval(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                    t('pages.newRfq.steps.registerBid.fields.priceIntervalError')
                                  ),
                          },
                          {
                            validator: (_, value) =>
                              validateValue(value)
                                ? Promise.resolve()
                                : Promise.reject(t('toast.invalidField')),
                          },
                        ]}
                        tooltip={{
                          title: t('pages.newRfq.steps.registerBid.fields.priceIntervalTooltip'),
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <Input
                          addonAfter={
                            <LiaDollarSignSolid
                              style={{ color: theme.colorsDesignSystem.primary }}
                            />
                          }
                          placeholder="0.00"
                          minLength={2}
                          maxLength={5}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Flex>
            </Form.Item>
          </S.StyledForm>
        )}
      </S.ContentForm>
      <S.StepFooter>
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>

        <StyledButton
          variant="primary"
          loading={isLoadingSave}
          onClick={() => {
            form.validateFields().then(async () => {
              handleSubmit(form.getFieldsValue(true));
            });
          }}
          htmlType="submit"
          disabled={isLoadingSave || isLoadingData}
        >
          {t('common.next')}
        </StyledButton>
      </S.StepFooter>
    </>
  );
}
