export const TRANSLATIONS_EN = {
  common: {
    send: 'send',
    dateFormat: 'MM/DD/YYYY',
    days: 'Days',
    upTo: 'Up to',
    confirm: 'Confirm',
    emptyTooltip: 'N/A',
    localeMoney: 'en',
    total: 'Total',
    active: 'Active',
    items: 'Items',
    inactive: 'Inactive',
    activate: 'Activate',
    deactivate: 'Deactivate',
    enable: 'Enable',
    disable: 'Disable',
    activateMessage: 'Are you sure you want to enable this user?',
    deactivateMessage: 'Are you sure you want to disable this user?',
    user: 'User',
    settings: 'Settings',
    report: 'Report',
    reports: 'Reports',
    action: 'Actions',
    add: 'Add',
    select: 'Select',
    unselect: 'Deselect',
    selected: 'Selected',
    search: 'Search',
    none: 'None',
    showAll: 'Show All',
    state: 'State',
    compareWith: 'Compare with',
    auto: 'Auto',
    manual: 'Manual',
    all: 'All',
    apply: 'Apply',
    by: 'By',
    cancel: 'Cancel',
    close: 'Close',
    cancelMessage: 'Are you sure you want to cancel?',
    characters: 'characters',
    copyMessage: 'Are you sure you want to copy?',
    clear: 'Clear',
    clearAll: 'Clear All',
    clearFilters: 'Clear Filters',
    create: 'Create',
    copy: 'Copy',
    delete: 'Delete',
    deleteMessage: 'Are you sure you want to delete?',
    deleteMessageAll: 'Are you sure you want to delete all?',
    edit: 'Edit',
    export: 'Export',
    filter: 'Filter',
    filtering: 'Filtering',
    filters: 'Filters',
    in: 'in',
    import: 'Import',
    item: 'Item',
    language: 'Language',
    loading: 'Loading',
    login: 'Log In',
    logout: 'Logout',
    lot: 'Bulk',
    no: 'No',
    ok: 'OK',
    register: 'Register',
    result: 'Result',
    rule: 'Rule',
    save: 'Save',
    submit: 'Submit',
    updated: 'Updated on',
    noRecords: 'No Records',
    view: 'View',
    viewAll: 'View All',
    yes: 'Yes',
    or: 'or',
    to: 'to',
    and: 'and',
    of: 'of',
    previous: 'Previous',
    next: 'Next',
    back: 'Back',
    page: 'Page',
    showing: 'Showing',
    selectAll: 'Select all',
    deleteAll: 'Delete All',
    moneyPlaceholder: '00.00',
    datePlaceHolder: 'Select Period',
    mandatoryField: 'Mandatory field',
    partNumber: 'Part Number',
    specification: 'Specification',
    description: 'Description',
    source: 'Source',
    manufacturer: 'Manufacturer',
    quarter: 'Quarter',
    fields: {
      uploadFile: 'Upload',
      uploadText: 'Click or drag a file to upload.',
      uploadDescription: 'Support for a single upload only.',
      uploadSuccess: 'Upload success.',
      adItem: 'Add Item',
    },
    results: {
      noDataTitle: 'No records found.',
      noDataSubtitle: 'Add or check filter params.',
      noScenarioTitle: 'No Scenarios Selected.',
      noScenarioSubtitle: 'Please, filter a scenario to proceed.',
      noProjectSubtitle: 'Please, select a project to proceed.',
      noProject: 'No project selected.',
      noPpb: 'No product selected',
    },
    validate: {
      required: 'Required',
      isRequired: 'is required',
      number: 'is not a valid number',
      typeNumber: 'Type a number',
      range: 'must be between',
      invalid: 'Invalid value',
      max: 'cannot be longer than',
      min: 'cannot be shorter than',
    },
    months: {
      january: 'Jan',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      august: 'Aug',
      september: 'Sep',
      october: 'Oct',
      november: 'Nov',
      december: 'Dec',
    },
    pagination: {
      page: '/ page',
      showing: 'Displaying',
      ofTotal: 'of the total of',
      items: 'items',
      jumpToPage: 'Go to page',
    },
    permissions: 'Permissions',
    score: 'Score',
    target: 'Target',
    trend: 'Trend',
    dayjs: 'en',
  },
  notifications: {
    title: 'Notifications',
    clearAll: 'Clear All',
    seeAll: 'See All',
    delete: 'Delete Notification',
    check: 'Check it out!',
    found: 'found',
    skuDesc: 'Attention! There are {{data}} SKUs without motherboard',
    qfDoneDesc: 'Attention, there are items with completed qualification',
    qfFailDesc: 'Attention, there are items with failed qualification',
    sku: 'SKUs without motherboard',
    copyMade: 'Copied to clipboard',
    noDataForRequest: "The system doesn't currently have data for this request.",
  },
  toast: {
    successOnSave: 'Item has been successfully saved',
    dataProcessing:
      'Edit saved successfully! We are processing the data and will notify you when it’s complete.',
    errorOnSave: 'Failed to save item',
    errorOnList: 'Failed to list data',
    successOnDelete: 'Item was successfully deleted',
    errorOnDelete: 'Failed to delete item',
    successOnUpload: 'Upload has been successfully saved',
    errorOnUpload: 'Failed to upload file',
    errorOnAuth: 'Please login to continue',
    errorOnLogin: 'Error to login, check your username and password',
    missingComponent: 'Component not found',
    missingScenario: 'Scenario not found',
    missingSupplier: 'Supplier not found',
    hasEmptyKeys: 'Found empty supplier or spec',
    requiredPricesFactors: 'Prices and factors files are required',
    exDuplication: 'Duplicate item',
    skuFormat: 'SKU out of format',
    skuNotFound: 'not found. To complete this action register this SKU',
    exporting: 'Exporting data',
    successOnExporting: 'CSV exported successfully',
    errorComponentMax: 'Invalid maximum value',
    errorComponentMin: 'Invalid minimum value',
    errorOnUserStatus: 'Failed to change user active status',
    successOnUserStatus: 'User status changed successfully',
    errorOnGroupDelete:
      'Not allowed to delete group! To do it, assign the following user(s) to another group!',
    errorDuplicateComponent: 'Cannot create duplicate grouping with the following component: ',
    errorDuplicateSpec: 'Cannot create duplicate grouping with the following spec: ',
    errorExporting: 'Failed to export',
    invalidEmail: 'Invalid contact email',
    noChanges: 'No changes were made',
    invalidField: 'Invalid value',
    uploadSuccessTitle: 'File import was successful!',
    uploadSuccessBody: 'The data is being processed and will be available in a few minutes',
    erroOnSendEmail: 'Failed to send email',
  },
  filter: {
    user: 'User',
    local: 'Local',
    scenario: 'Scenario',
    selectPlaceholder: 'Select multiple items',
    sku: 'SKU',
    specShort: 'Spec',
    partType: 'Component Type',
    spec: 'Specification',
    partNumber: 'Part Number',
    supplier: 'Supplier',
    project: 'Project',
    version: 'Version',
    year: 'Year',
    status: 'Status',
    qStatus: 'Q-Status',
    product: 'Product',
    location: 'Source',
    vendor: 'Manufacturer',
  },
  sort: {
    orderBy: 'Click to sort',
    orderByAsc: 'Click to sort ascending',
    orderByDesc: 'Click to sort descending',
    cancelSort: 'Click to cancel sorting',
  },
  pages: {
    logusers: {
      title: 'Log Users',
      list: 'List',
      confirmMessage: 'Are you sure process this file again?',
      tooltipMessage: 'Process file',
      successMessage: 'File added to processing queue',
      successDeleteMessage: 'Logs deleted successfully',
      selectDate: 'Select a date',

      fields: {
        modifiedDate: 'Date',
        user: 'User',
        area: 'Area',
        message: 'Message',
        startDate: 'Start Date',
        endDate: 'End Date',
        chooseDate: 'Choose the Date Range',
      },

      registerForm: {
        title: 'Register',
      },
      editForm: {
        title: 'Edit component',
      },
      filter: {
        selectPlaceholder: 'Select multiple items',
      },
    },
    fileerrors: {
      title: 'Files Logs',
      list: 'List',
      confirmMessage: 'Are you sure process this file again?',
      tooltipMessage: 'Process file',
      successMessage: 'File added to processing queue',
      successDeleteMessage: 'Logs deleted successfully',
      processMultipleMessage: 'Process {{files}} files',

      fields: {
        message: 'Message',
        file: 'File',
        status: 'Status',
        modifiedDate: 'Date',
      },

      registerForm: {
        title: 'Register',
      },
      editForm: {
        title: 'Edit component',
      },
      filter: {
        selectPlaceholder: 'Select multiple items',
      },
    },
    components: {
      title: 'Components',
      list: 'List',

      fields: {
        spec: 'Specification',
        subComponent: 'Sub Components',
        partType: 'Category',
        partNumber: 'Part Number',
        description: 'Description',
        local: 'Source',
        leadTime: 'Lead Time',
      },
      labels: {
        cm: 'CM',
        product: 'Product',
        category: 'Category',
        specification: 'Specification',
        partNumber: 'Part Number',
        description: 'Description',
        source: 'Source',
        leadTime: 'Lead Time',
      },
      placeHolders: {
        cm: 'Select CM',
        product: 'Select Product',
        category: 'Select Category',
        specification: 'Add Specification',
        partNumber: 'Add Part Number',
        description: 'Add Description',
        source: 'Select Source',
        leadTime: 'Select day range',
      },

      registerForm: {
        title: 'Register',
      },
      editForm: {
        title: 'Edit component',
      },
      filter: {
        selectPlaceholder: 'Select multiple items',
      },
    },
    componentsGroup: {
      title: 'Components Grouping',
      fields: {
        partType: 'Category',
        groupName: 'Group Name',
        spec: 'Specification',
        partNumber: 'Part Number',
        supplier: 'Supplier',
        components: 'Components',
        component: 'Component',
        item: 'Item',
      },
      modalTax: {
        modalTaxTitle: 'Net Cost Component',
        title: 'Setting Taxes',
        tootip: 'Calculated by the system',
        table: {
          componentType: 'Category',
          from: 'From',
          to: 'To',
          ipi: 'IPI %',
          icms: 'ICMS %',
          pisCofins: 'PIS/COFINS %',
          rd: 'R&D %',
          recoverable: 'Recoverable',
          period: 'Period',
        },
      },
    },
    demands: {
      title: 'Demands',
      description: 'Demand page',

      fields: {
        project: 'Project',
        model: 'Model',
        modelName: 'Model Name',
        sku: 'SKU',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Set',
        october: 'Out',
        november: 'Nov',
        december: 'Dec',
        total: 'Total',
      },
    },
    forgotPassword: {
      title: 'Retrieve Password',
      description: 'Retrieve Password',
      codeSentMessage: 'The code has been sent to your email',
      passwordChangedMessage: 'Password changed successfully',
      fields: {
        title: 'Retrieve Password',
        titleChange: 'Change Password',
        username: {
          label: 'Username',
          messageRequired: 'Please enter your username',
        },
        email: {
          label: 'Email',
          messageRequired: 'Please enter your email',
          messageValidity: 'Please enter a valid email',
        },
        confirmationCode: {
          label: 'Confirmation Code',
          messageRequired: 'Please enter the code you received in your email',
        },
        btnRetrievePW: 'Retrieve Password',
      },
    },
    home: {
      description: 'Initial page',
      title: 'Home',
    },
    login: {
      docTitle: 'Login',
      title: 'Enter with your username and password',
      description: 'Página de login',
      fields: {
        title: 'Log In',
        username: {
          label: 'Username',
          messageRequired: 'Please enter your username',
        },
        email: {
          label: 'Email',
          messageRequired: 'Please enter your email',
          messageValidity: 'Please enter your email',
        },
        password: {
          label: 'Password',
          messageRequired: 'Please enter your password',
        },
        remember: {
          label: 'Remember me',
        },
      },
      linkForgotPassword: 'I forgot my password',
    },
    groups: {
      title: 'Groups',
      addGroup: 'Add Group',
      editGroup: 'Edit Group',
      fields: {
        name: 'Name',
        created: 'Created at',
        updated: 'Updated at',
      },
    },
    reports: {
      scenarios: {
        title: 'Scenarios Report',
        titleMenu: 'Scenarios',
        fields: {
          sku: 'SKU',
          spec: 'Specification',
          componentAmount: 'Quantity',
          partType: 'Category',
          partNumber: 'Part Number',
          local: 'Source',
        },
      },
    },
    user: {
      title: 'Users',
      description: 'Users Page',
      addUser: 'Add User',
      editUser: 'Edit User',
      fields: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        group: 'Group',
        enabled: 'Enabled',
        disabled: 'Disabled',
        status: 'Status',
        password: 'Password',
        passwordTemp: 'Temporary Password',
        passwordMessage:
          'Password must contain uppercase, lowercase, number and at least 8 characters',
        created: 'Created at',
        updated: 'Updated at',
      },
      registerPasswordForm: {
        title: 'Register Password',
        titleFirst: 'Register a new password <br />for your first login',
        username: {
          label: 'Username',
        },
        current: {
          label: 'Old password',
          messageRequired: 'Please enter your old password',
        },
        password: {
          label: 'New Password',
          messageRequired:
            'Password must contain uppercase, lowercase, number and at least 8 characters',
        },
        confirm: {
          label: 'Confirm Password',
          messageRequired: 'Please enter your confirm password',
          messageDontMatch: 'The two passwords that you entered do not match',
        },
      },
    },
    suppliers: {
      title: 'Suppliers',
      fields: {
        name: 'Supplier Name',
        shortName: 'Short Name',
        code: 'Code',
        state: 'State',
        action: 'Components',
      },
    },
    ppb: {
      fields: {
        description: 'Description',
        partTypes: 'Category',
        maxScore: 'Max Score',
        bonusStage: 'Bonus Stage',
        calcBase: 'Calc Base',
        taxOrigins: 'Tax Origins',
        ignoreSKUs: 'Ignore SKUs',
        countSubComponent: 'Count sub component',
        notCountSubComponent: 'Not count sub component',
        variablesScore: {
          title: 'Variables Score',
          critical: 'Critical',
          highRisk: 'High risk',
          good: 'Good',
          min: 'Min',
          max: 'Max',
          minError: 'Value cannot exceed Max',
          criticalMaxError: "Value can't exceed high risk min",
          highRiscMaxError: "Value can't exceed good min",
          saveError: 'Fill the max and min input  ​​for at least one Variables Score field.',
          fieldsError: 'To continue, fix the incorrect values!',
        },
      },
    },
    ppbProducts: {
      title: 'PPB Products',
      fields: {
        product: 'Product',
        productName: 'Product Name',
        description: 'Description',
        targetScore: 'Score',
        inForceDate: 'Effective as of',
        publishedDate: 'Published Date',
        modified: 'Modified Date',
        note: 'Note',
      },
    },
    prices: {
      title: 'Prices',
      showPrices: 'Show Prices',
      hidePrices: 'Hide Prices',
      showPricesAlert: 'Are you sure you want to show prices?',
      hidePricesAlert: 'Are you sure you want to hide prices?',
      checkPassword: 'Wrong Password',
      fields: {
        applyMultiple: 'Edit bulk Price',
        price: 'Price',
        partNumber: 'Part Number',
        supplier: 'Supplier',
        spec: 'Specification',
        local: 'Local',
        password: 'Password',
      },
    },

    factors: {
      title: 'Factors',
      fields: {
        applyMultiple: 'Edit bulk Factor',
        factor: 'Factor',
        supplier: 'Supplier',
        spec: 'Specification',
        local: 'Local',
      },
      views: {
        view: 'View',
        prices: 'Prices',
        factors: 'Factors',
        priceFactorGap: 'Prices, Factors and Gap',
        priceFactor: 'Prices and Factors',
        groupBySupplier: 'Group By Supplier',
        groupBySpec: 'Group By Specification',
      },
    },
    bom: {
      editForm: 'Edit component',
      registerForm: 'Register',
      title: 'BOM',
      noMotherboard: 'NO MOTHERBOARD',
      fields: {
        project: 'Project',
        model: 'Model',
        modelName: 'Model Name',
        sku: 'SKU',
        spec: 'Specification',
        amount: 'Qty',
        partType: 'Category',
        CT: 'C.T',
        partNumber: 'Part Number',
        PN: 'P.N',
        local: 'Local',
        description: 'Description',
      },
    },
    scenarios: {
      title: 'Scenarios',
      titleSingular: 'Scenario',
      subtitle1: 'User created scenarios',
      subtitle2: 'Base Scenarios - Automatic according to Forecast',
      register: 'Create Scenario',
      registerUpdate: 'Update Scenario',
      registerUser: 'Create User Scenario',
      registerUserUpdate: 'Update User Scenario',
      tableTitle: 'Scenario Tables',
      simulate: 'Simulate',
      copySimple: 'Copy',
      deleteSimple: 'Delete',
      copy: 'Copy Scenario',
      delete: 'Delete Scenario',
      factor: 'Factor',
      step1: 'Step 01 - Base Scenario',
      step2: 'Step 02 - Price + Factor',
      nextStep: 'Next Step',
      prevStep: 'Previous Step',
      settings: {
        title: 'Scenario Settings',
        specificRules: 'Specific Rules',
        tooltip: 'Scenario Tooltip',
        details: 'Show details of specific rule',
        componentPurchaseMaxLabel: 'Maximum - Component x Supplier',
        componentPurchaseMax:
          'Establish a maximum purchase quantity of a certain component with a certain supplier.',
        componentPurchaseMinLabel: 'Minimum - Component x Supplier',
        componentPurchaseMin:
          'Establish a minimum purchase quantity of a certain component with a certain supplier.',
        componentPurchaseNotNationalLabel: 'Restriction of National Components',
        componentPurchaseNotNational:
          'Define which components are no longer purchased in the national market.',
        componentWithoutPriceLabel: 'Simulate Without Price',
        componentWithoutPrice: 'Set simulation without price.',
        componentWithoutQGLabel: 'Simulate With Qualification Ongoing Components',
        componentWithoutQG: 'Set simulation with qualification ongoing components',
        fields: {
          partType: 'Category',
          spec: 'Specification',
          partNumber: 'Part Number',
          description: 'Description',
          supplier: 'Supplier',
          amount: 'Limit Quantity',
          amountMax: 'Max. Quantity',
          amountMin: 'Min. Quantity',
        },
      },
      fields: {
        targetYear: 'Target Year',
        version: 'Version',
        name: 'Scenario Name',
        base: 'Base Scenario',
        targetScore: 'Target Score',
        currentScore: 'Current Score',
        selectPrice: 'Select the Price Table',
        selectFactor: 'Select the Factor Table',
        addPrice: 'Add Price Table',
        addFactor: 'Add Factor Table',
        modified: 'Last Update',
        created: 'Created at',
        newScenario: 'Title New User Scenario',
        updateScenario: 'Title User Scenario',
        ppbRule: 'PPB Rule',
        titlePrice:
          'Choose a <strong>model scenario</strong> <span>or</span> upload the <strong>price</strong> and <strong>factor</strong> tables to be used in the new scenario:',
        titlePriceUpdate:
          'Choose a <strong>model scenario</strong> <span>or</span> upload the <strong>price</strong> and <strong>factor</strong> tables to be used in this scenario:',
      },
    },
    simulation: {
      title: 'Simulation',
      isRefreshing: 'Updating simulation...',
      loading: 'Loading simulation...',
      refreshMessage: 'Updated user scenario available',
      refreshButton: 'Update',
      refreshTooltip:
        'BOM, Price or Forecast were updated. Click the update button to check the last simulation available.',
      refreshConfirm: 'Confirm update scenario?',
      tableSummary:
        'Summary with total and percentage in PPB, Quantity and Simulation and by grouping',
      tooltipCurrentScore: 'Calculated Achievement',
      tooltipMaxScore: 'Max Achievable ',
      tooltipPartTypeMaxScore: 'PPB Rule',
      label: {
        cm: 'CM',
        product: 'Product',
        project: 'Project',
        category: 'Category',
        targetYear: 'Target Year',
        scenario: 'Scenario',
      },
      placeholder: {
        cm: 'Select CM',
        product: 'Select Product',
        project: 'Select Project',
        category: 'Select Category',
        targetYear: 'Select Target Year',
        scenario: 'Select Scenario',
      },
      fields: {
        typeSpec: 'Type<br />Spec',
        month: 'Month',
        points: 'Points',
        baseForecast: 'Base Forecast',
        baseScenario: '<span>Automatic Simulator <br /> User Scenario</span>',
        userForecast: 'User Forecast',
        userScenario: '<span>Manual Simulator <br /> User Scenario</span>',
        category: 'Category',
        costTotal: 'Total Cost',
        totalGap: 'Total Gap',
        costPerPoint: 'Cost Per Point',
        score: 'Score',
        scoreModal: 'Score<br />Total | Acc',
        titleUserScenario: '2021 teste <br /> (1.0)',
        titleBaseScenario: 'CEB <br /> (33.0)',
        titleInvest: 'Invest/Point ($)',
        titleCostGap: 'Total Gap ($)',
        titleCostTotal: 'Total Cost ($)',
        kcTotal: 'KC Total ($)',
      },
      fieldsHeader: {
        partType: 'Category',
        month: 'Month',
        fgDemand: 'FG Demand',
        base: 'Base',
        user: 'User',
        kc: 'KC',
        totalQty: 'Total Qty',
        qualification: 'Qualification',
        PPB: 'PPB (%)',
        currentScore: 'Total Score',
        totalCost: 'Total Cost ($)',
        totalGap: 'Total Gap ($)',
        score: 'Score',
      },
    },
    score: {
      title: 'Score',
    },
    sku: {
      title: 'SKU',
      fields: {
        sku: 'SKU',
        projectName: 'Project Name',
        modelName: 'Model Name',
        created: 'Created at',
        createdBy: 'Created by',
        updated: 'Updated at',
        updatedBy: 'Updated by',
      },
      filters: {
        product: 'Product',
        productPlaceholder: 'Select Product',
        cm: 'CM',
        cmPlaceholder: 'Select CM',
        project: 'Project',
        projectPlaceholder: 'Add Project Name',
        sku: 'SKU',
        skuPlaceholder: 'Selecect SKU',
        modelName: 'Model Name',
        modelNamePlaceholder: 'Select Model Name',
        otherProducts: 'Other',
      },
    },
    avlc: {
      title: 'AVLC',
      fields: {
        projectName: 'Project Name',
        partType: 'Category',
        spec: 'Specification',
        partNumber: 'Part Number',
        cm: 'CM',
        cmPlaceholder: 'Select CM',
        product: 'Product',
        productPlaceholder: 'Select Product',
        registeredComponent: 'Registered Component',
        qStatus: 'Q-Status',
        project: 'Project',
        description: 'Description',
        modelNumber: 'Model Number',
        odm: 'ODM',
        odmSample: 'ODM Sample Receive',
        odmActual: 'ODM Actual Sample Receive',
        location: 'Source',
        vendor: 'Manufacturer',
        priority: 'Priority',
        avlcType: 'AVLC Type',
        purpose: 'Part Purpose',
        remark: 'Remark',
        lock: 'Lock',
        firstPlanDate: '1st Plan Date',
        rdPlanFinishDate: 'RD Finish (plan) Date',
        rdActualFinishDate: 'RD Finish Actual Date',
        adjustDate: 'Adjust Finish Date',
        uploadTime: 'Upload Time',
        uploadUser: 'Upload User',
        family: 'Family',
        partNumberTooltip: 'This part number is not registered as a component',
        subComponents: 'Sub Components',
        subComponentsPlaceholder: 'ADD QTY',
        dataRegistration: 'Data Registration',
        modalName: 'Add Component',
      },
      filters: {
        project: 'Project',
        projectPlaceholder: 'Add Project Name',
        category: 'Category',
        categoryPlaceholder: 'Add Category',
        specification: 'Specification',
        specificationPlaceholder: 'Add Specification',
        partNumber: 'Part Number',
        partNumberPlaceholder: 'Add Part Number',
        source: 'Source',
        sourcePlaceholder: 'Add Source',
        manufacturer: 'Manufacturer',
        manufacturerPlaceholder: 'Add Manufacturer',
        status: 'Q-Status',
        statusPlaceholder: 'Select Status',
        noRegistryComponent: 'No registry as component',
      },
    },
    spot: {
      title: 'Spot Strategies',
      fields: {
        groupName: 'Group Name',
        local: 'BR',
        import: 'FOB',
        currentScore: 'Current Score',
        maxScore: 'Max. Score',
        points: 'points',
        totalScore: 'Total Score',
      },
    },
    alert: {
      title: 'List of Alerts',
      menuTitle: 'Alerts',
      fields: {
        alerts: 'Alerts',
        alert: 'Alert',
        day: 'day',
        description: 'Description',
        activate: 'Activate Alert',
        date: 'Date',
        time: 'Time',
        frequency: 'Frequency',
        frequencyImmediately: 'Whenever it occurs',
        ShowFileRemarks: 'Show file remarks',
        actions: 'Actions',
        users: 'Select the users who will receive this notification',
        sendEmail: 'Send email notifications?',
        editFrequency: 'Frequency of sending the alert',
        emailMsg: 'Email message',
        no: 'No',
        yes: 'Yes',
        forAll: 'For All',
        forSome: 'For Some',
      },
      validate: {
        contact: 'One of the contacts',
        contacts: 'At least 1 contact',
        emailMsg: 'Email message',
        emailInvalid: 'is not a valid email',
      },
    },
    skuAlert: {
      title: 'SKUs without Motherboard',
      fields: {
        found: 'found',
        sku: 'SKU',
        projectName: 'Project Name',
        modelName: 'Model Name',
      },
    },
    notifications: {
      title: 'Notifications',
      fields: {
        alert: 'Alert',
        dateTime: 'Date - Time',
      },
    },
    dashboard: {
      title: 'Share Proportion',
      titleSku: 'SKU | Demand x Occurred',
      titleShareProportion: 'Share Proportion | Quantity',
      titleNavShareProportion: 'Share Proportion',
      labelProduct: 'Product',
      placeholderProduct: 'Select Product',
      labelCM: 'CM',
      placeholderCM: 'Select CM',
      labelProject: 'Project',
      placeholderProject: 'Select Project',
      labelComponents: 'Categories',
      placeholderComponents: 'Select Component',
      labelYear: 'Year',
      fields: {
        title: 'Share Proportion - Quantity',
        costTrend: 'Demand x Occurred',
        month: 'Month',
        cost: 'Cost',
        occurred: 'Occurred',
        forecast: ' Demand',
        scenario: 'Scenario',
        newProjects: 'New Projects',
        finishedProjects: 'Finished Projects',
        projectName: 'Project Name',
        modelName: 'Model Name',
        demands: 'Demands',
        scorePerformed: 'Score Performed',
        accumulatedScore: 'Accumulated Score',
        topList: 'Top List',
        componentTypes: 'Component Types',
        category: 'Category',
        months: 'Months',
        projects: 'Projects',
        components: 'Categories',
      },
    },
    finance: {
      title: 'Finance',
      dashboardNavbar: 'Dashboards',
      uploadFiles: {
        menuTitle: 'Upload Files',
        title: 'Spreadsheets',
        newUpload: 'New Upload',
        from: 'From',
        labelDate: 'Date',
        labelPeriod: 'Period',
        labelListType: 'List Type',
        labelResposible: 'Responsible',
        labelStatus: 'Status',
        listPlaceHolder: 'Select List Type',
        datePlaceHolder: 'Select Date',
        periodPlaceHolder: 'Select Period',
        uploadAgain: 'Upload Again',
        notificationTitle: 'File import was successful!',
        notificationBody: 'The data is being processed and will be available in a few minutes',
        fileSuccessTitle: 'Processing completed!',
        fileSuccessTBody: 'The imported file was processed successfully!',
        fileProcessingTitle: 'File in processing',
        fileProcessingBody: ' In a few moments, the imported data will be available in the system',
        layoutErrorTitle: 'Layout Error',
        layoutErrorBody:
          "We've identified that the layout of your file doesn't align with the standards required by the system. To proceed, adjust the data in the file and upload your updated version.",
        formatErrorTitle: 'File Format Incompatibility',
        formatErrorBody:
          'It seems that the format of your file is not compatible with the standard recognized by the system. To proceed, please make sure to use one of the accepted formats: .xls, .xlsx.',
        fields: {
          cm: 'CM',
          listType: 'List Type',
          date: 'Date',
          period: 'Period',
          hour: 'Hour',
          importedDate: 'Imported Date',
          responsible: 'Responsible',
          uploadFile: 'Upload Files',
          status: 'Status',
        },
        filterStatusOptions: {
          success: 'Success',
          processing: 'Processing',
          error: 'Error',
        },
      },
      dashboard: {
        titleFinance: 'Finance Panel',
        titleProcurement: 'Procurement Panel',
        labelProduct: 'Product',
        placeholderProduct: 'Select Product',
        labelPeriod: 'Period',
        labelCM: 'CM',
        placeholderCM: 'Select CM',
        labelDate: 'Date',
        placeholderLevel: 'Select Level',
        labelLevel: 'Level',
        placeholderProject: 'Select Project',
        labelProject: 'Project',
        applyButton: 'Apply',
        labelType: 'Type',
        placeholderType: 'Select Type',
        labelListType: 'List Type',
        placeholderListType: 'Select List Type',
        table: {
          labels: {
            projectName: 'Project Name',
          },
          placeholders: {
            projectName: 'Add Project',
            sku: 'Add SKU',
          },
        },
        headerTitles: {
          projectName: 'Project Name',
          unit: 'Unit',
          monthDemand: 'Month Demand',
        },
        titleCards: {
          firstCard: 'SKU Cost',
          secondCard: 'Demand Per Month',
          thirdCard: 'Negotiated vs Executed',
        },
        skuComponents: {
          table: {
            labels: {
              category: 'Select Category',
              lowerPrice: 'Lower Price',
              higherPrice: 'Higher Price',
              seeQuotes: 'See Quotes',
            },
            headers: {
              category: 'Category',
              source: 'Source',
              cmQuote: 'CM Quote',
            },
          },
        },
        supplierCostTrend: {
          titleCard: 'Cost Trend',
          filters: {
            placeholder: {
              category: 'Select Category',
            },
          },
        },
        startvsend: {
          start: 'Start',
          end: 'End',
          legendStart: 'Start Stock',
          legendEnd: 'End Stock',
          selectCategory: 'Select category',
        },
      },
    },
    procurement: {
      dashboard: {
        labelCM: 'CM',
        table: {
          labels: {
            projectName: 'Project Name',
          },
          placeholders: {
            projectName: 'Add Project',
            sku: 'Add SKU',
          },
        },
        headerTitles: {
          projectName: 'Project Name',
          unit: 'Unit',
          monthDemand: 'Month Demand',
        },
        labelsGraphDemandPerMonth: {
          before: 'Month Ago',
          current: 'This Month',
          after: 'Next Month',
        },
      },
      chart: {
        placeHolder: {
          componentGroup: 'Component Group',
          spec: 'Specification',
          partNumber: 'Add Part Number',
          source: 'Source',
          supplier: 'Supplier',
          month: 'Month',
        },
        chartTitle: {
          unitPrice: 'UNIT PRICE',
          totalInvoice: 'TOTAL INVOICE',
        },
        chartBarsLabel: {
          negotiated: 'Negotiated',
          executed: 'Executed',
          gap: 'Gap',
        },
      },
      supplierPriceCostTrend: {
        cardTitle: {
          supplierPrices: 'Supplier Prices',
          costTrend: 'Cost Trend',
        },
        placeHolder: {
          specification: 'Specification',
          projectName: 'Project Name',
        },
        blended: 'BLENDED',
        average: 'Average',
        priceBook: 'Price Book',
        usd: 'USD',
        noLegend: 'No legend',
        shareProportion: 'Share Proportion',
      },
    },
    taxes: {
      title: 'Setting Taxes',
      addTax: 'Add Tax',
      labels: {
        linkTo: 'Link to',
        logistic: 'Logistic',
      },
      fields: {
        componentType: 'Category',
        from: 'From',
        to: 'To',
        ipi: 'IPI %',
        icms: 'ICMS%',
        pisCofins: 'PIS/COFINS %',
        periodFrom: 'Period From',
        periodTo: 'Period To',
        recoveryPisCofins: ' Recovery PIS/COFINS %',
        recoveryICMS: ' Recovery IPI %',
        recoveryIpi: ' Recovery ICMS%',
        recoveryRd: 'Recovery R&D %',
        period: 'Period',
      },
      placeholders: {
        component: 'All component types',
        from: 'From',
        to: 'To',
      },
    },

    pricesTaxes: {
      title: 'Prices Taxes',
      fields: {
        cm: 'CM',
        cmPlaceholder: 'Select CM',
        category: 'Category',
        categoryPlaceholder: 'Add Category',
        source: 'Source',
        sourcePlaceholder: 'Select Source',
        spec: 'Specification',
        specPlaceholder: 'Add Specification',
        partNumber: 'Part Number',
        partNumberPlaceholder: 'Add Part Number',
        supplier: 'Supplier',
        supplierPlaceholder: 'Add Supplier',
        logistic: 'Logistic',
        from: 'From',
        to: 'To',
        grossPrice: 'Gross, Price, $',
        ipi: 'IPI,%',
        icms: 'ICMS,%',
        pisCofins: 'PIS/COFINS,%',
        netPrice: 'Net,Price, $',
        recovery: 'Recovery',
        costAfterCredit: 'Cost,After Credit,$',
        action: 'Action',
        period: 'Period',
      },
      fieldsCsv: {
        grossPrice: 'Gross Price',
        ipi: 'IPI',
        icms: 'ICMS',
        pisCofins: 'PIS/COFINS',
        netPrice: 'Net Price',
        costAfterCredit: 'Cost After Credit',
      },
    },
    quotes: {
      title: 'Quotes',
      tabTitles: {
        quotes: 'QUOTES',
        stocks: 'STOCKS',
      },
      titleFilters: 'Choose the options below to bring the specified information',
      labels: {
        product: 'Product',
        cm: 'CM',
        date: 'Period',
        project: 'Project',
        sku: 'SKU',
        level: 'Level',
        amount: 'Amount',
        quoteFiles: 'Quotes Files',
        estimatedDemand: 'Estimated Demand',
        withDemand: 'With Demand',
        emptyTooltip: 'N/A',
      },
      tableHeaders: {
        projectModelName: 'Project Model Name',
        materialFob: 'Total Imported Material FOB                                    II',
        cpu: 'CPU',
        chipset: 'ChipSet',
        vga: 'VGA',
        vram: 'VRAM',
        memory: 'Memory',
        hdd: 'HDD',
        freightCkd: 'Sea Freight for CKD material',
        insuranceMaterial: 'Insurance for CKD material',
        materialLanded: 'Total Imported Material Landed',
        importTax: 'Import Tax',
        suframaTax: 'Suframa Tax - TCIF',
        otherExpenses: 'Other Import Expenses',
        localBuy: 'Local Buy',
        localBuyIpiRebate:
          'Local Buy                                                              IPI Rebate %',
        totalCost: 'Total Material Cost',
        net: 'Net',
        netSalesPrice: 'Net Sales Price',
        grossSellingPrice: 'Gross Selling Price',
        emsManufacturingCost: 'EMS manufacturing cost',
        mvaUsdPart: 'MVA USD Part',
        finance: 'Finance',
        hedgeDto: 'Hedge',
        freigthJundiaiAcer: 'Freight from CM at Jundiaí to Acer',
        projectInvestiments: 'Project Investment / NRE',
        nationalCost: 'National Cost / Discounts / Compensations',
        PPBBenefit: 'PPB Benefit',
        RD: 'R&D',
        adapter: 'Adapter',
        ee: 'EE',
        me: 'ME',
        wifi: 'WIFI',
        ssd: 'SSD',
        lcm: 'LCM',
        battery: 'Battery',
        icms: 'ICMS',
        icmsst: 'ICMS-ST',
        pis: 'PIS',
        cofins: 'Cofins',
        ipi: 'IPI',
        importCofins: 'Import Cofins ( 1% )',
        packingLocal: 'Packing Local',
        packing: 'Packing',
        packingMaterial: 'Packing Material',
        motherboard: 'Motherboard from Manaus',
        pisCofins: 'PIS/Cofins credit of MB from Manaus',
        ipiCredit: 'IPI credit of MB from Manaus',
        mvaBrlPart: 'MVA BRL Parte convertida em USD',
        mvaSuportCompalModels: 'MVA Support for Compal Models',
        others: 'Project Investments por Project Invest. / NRE',
        icmsRebate: 'ICMS Rebate',
        pisCofinsIcmsRebate: 'PIS&COFINS Over ICMS Rebate',
      },

      placeholders: {
        product: 'Add Product',
        cm: 'Add CM',
        sku: 'Add SKU',
        date: 'Select Period',
        project: 'Add Project',
        level: 'Add Level',
        foreCast: 'With forecast',
      },
      titleButons: {
        apply: 'Apply',
      },
    },

    average: {
      title: 'Average',
      requiredFieldMessages: {
        date: 'Period is required',
        partNumber: 'Part Number is required',
      },
      filtersLabels: {
        date: 'Period',
        cm: 'CM',
        project: 'Project',
        sku: 'SKU',
        category: 'Category',
        level: 'Level',
        source: 'Source',
        partNumber: 'Part Number',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        qtyPurchase: 'Purchase',
        price: 'Price',
        invNo: 'Inv. No.',
        invDate: 'Inv. Date',
        weight: 'Weight',
        average: 'Average',
        quote: 'Quote',
        demand: 'Demand',
        total: 'Total',
        current: 'Current',
        before: 'Before',
        use: 'Use',
        inventory: 'Inventory',
      },
    },
    newDemand: {
      title: 'Demands',
      titleModal: 'SKU Details',
      totalizer: 'Total Month',
      tableName: 'Demands',
      modalFields: {
        components: 'Components',
        projectName: 'Project Name',
        cm: 'CM',
        description: 'Description',
        adapters: 'Adapters',
        batteries: 'Batteries',
        memory: 'Memory',
        ssd: 'SSD',
        wirelessLan: 'Wireless Lan',
        vram: 'VRAM',
        modelName: 'Model Name',
        messageRequired: 'Value',
      },
      filters: {
        product: 'Product',
        cm: 'CM',
        year: 'Year',
        sku: 'SKU',
        project: 'Project',
      },
      placeholders: {
        product: 'Select Product',
        cm: 'Select CM',
        year: 'Year',
        sku: 'Add SKU',
        project: 'Add Project Name',
      },
      titleButtons: {
        apply: 'Apply',
      },
      tableHeaders: {
        sku: 'SKU',
        model: 'Model',
        total: 'Total',
        project: 'Project',
      },
      noInformation: 'Not informed',
    },
    packingPrices: {
      title: 'Packing',
      total: 'Totals',
      filters: {
        cm: 'CM',
        project: 'Project',
        PartNumber: 'Part Number',
        spec: 'Specification',
        quarter: 'Quarter',
      },
      tableHeaders: {
        logo: 'Logo',
        PartNumber: 'Part Number',
        description: 'Description',
        qtyPer: 'QTY per',
        source: 'Source',
        netPrice: 'Net Cost',
        gross: 'Gross Price',
        taxes: 'Taxes',
      },
      placeholders: {
        cm: 'Select CM',
        project: 'Add Project Name',
        parNumber: 'Add Part Number',
        specification: 'Add Specification',
        quarter: 'Select Quarter',
      },
      error: {
        quarter: 'inform the quarter',
      },
      tooltips: {
        na: 'N/A',
        exRate: 'Total ExRate',
      },
    },
    componentsPrices: {
      Title: 'Components Prices',
      modal: {
        title: 'Your data is ready!',
        message: 'You need to refresh the page to view it.Would you like to do that now?',
      },
      alertDateError: 'The quarters must be different!',
      titleButtons: {
        apply: 'Apply',
      },
      tabTitles: {
        prices: 'Prices',
        packing: 'Packing',
      },
      labels: {
        category: 'Category',
        partNumber: 'Part Number',
        specification: 'Specification',
        supplier: 'Supplier',
        source: 'Source',
        quarter: 'Quarter',
        period: 'Period',
        cm: 'CM',
      },
      placeHolders: {
        category: 'Select Category',
        partNumber: 'Add Part Number',
        specificaton: 'Add Specification',
        supplier: 'Add Supplier',
        source: 'Add Source',
        quarter: 'Add Quarter',
        cm: 'Select CM',
        startQuarter: 'Start Quarter',
        endQuarter: 'End Quarter',
      },
      tableHeaders: {
        components: 'Components',
        partNumber: 'Part Number',
        specification: 'Specification',
        supplier: 'Supplier',
        source: 'Source',
        subHeader: {
          gross: 'Gross Price',
          netCost: 'Net Cost',
        },
      },
    },

    financePage: {
      labels: {
        quuotesPrice: 'Quotes Price',
        monthYear: 'Month | Year',
        category: 'Category',
        cm: 'CM',
        source: 'Source',
        quotes: 'Quotes',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        demand: 'Demand',
        qty: 'QTY',
        unitPrice: 'Unit Price',
        totalInvoiceFbr: `Total Invoice FBR`,
        ii: 'II%',
        cofins: 'COFINS%',
        cif: 'CIF',
        vendorName: 'Vendor Name',
      },
    },

    inventory: {
      title: 'Inventory',
      mandatoryField: 'Only Numbers',
      noContent: 'No data available for the selected date. Please try another date.',
      labels: {
        product: 'Product',
        cm: 'CM',
        category: 'Category',
        specification: 'Specification',
        partNumber: 'Part Number',
        source: 'Source',
        supplier: 'Supplier',
        date: 'Date',
        apply: 'Apply',
      },
      placeholders: {
        product: 'Select Product',
        cm: 'Select CM',
        category: 'Add Category',
        specification: 'Add Specification',
        partNumber: 'Add Part Number',
        source: 'Select Source',
        supplier: 'Add Supplier',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        specification: 'Specification',
        project: 'Project',
        cm: 'CM',
        source: 'Source',
        supplier: 'Supplier',
        totalOnHand: 'Total On Hand',
        allocated: 'Allocated to Production Orders',
        freeQty: 'Free QTY',
        value: 'Value',
        valueUsd: '(USD)',
      },
      titlesTab: {
        inventoy: 'Inventory',
        inOut: 'IN/OUT',
      },
    },
    inOut: {
      modalTitle: 'Charts IN/OUT',
      partNumbers: 'Part Numbers',
      labels: {
        cm: 'CM',
        product: 'Product',
        category: 'Category',
        specification: 'Specification',
        source: 'Source',
        partNumber: 'Part Number',
        month: 'Month',
        description: 'Description',
      },
      placeholders: {
        cm: 'Select CM',
        product: 'Select Product',
        category: 'Add Category',
        specification: 'Add Specification',
        source: 'Select Source',
        partNumber: 'Add Part Number ',
      },
      tablesHeaders: {
        partNumber: 'Part Number',
        specification: 'Specification',
        source: 'Source',
        cmPn: 'CM PN',
        mba: 'MBA',
        receive: 'Receive',
        return: 'Return',
        consumed: 'Consumed',
        scrap: 'Scrap',
        endMonth: 'End Month',
        currentMonth: 'Current Month',
        nextMonth: 'Next Month',
        variance: 'Variance',
        creditDebit: 'Credit/Debit',
        tax: 'Tax',
        total: 'Total',
        totalQuarter: 'Total Quarter',
      },
    },
    shareProportion: {
      title: 'Share Proportion',
      noContent: 'No data available for the selected date. Please try another date.',
      labels: {
        product: 'Product',
        cm: 'CM',
        category: 'Category',
        specification: 'Specification',
        partNumber: 'Part Number',
        source: 'Source',
        supplier: 'Supplier',
        date: 'Period',
        project: 'Project',
        apply: 'Apply',
      },
      placeholders: {
        product: 'Select Product',
        cm: 'Select CM',
        category: 'Add Category',
        specification: 'Add Specification',
        partNumber: 'Add Part Number',
        source: 'Select Source',
        project: 'Project',
        supplier: 'Add Supplier',
      },
      tableHeaders: {
        partNumber: 'Part Number',
        specification: 'Specification',
        project: 'Project',
        cm: 'CM',
        source: 'Source',
        supplier: 'Supplier',
        totalOnHand: 'Total On Hand',
        allocated: 'Allocated to Production Orders',
        freeQty: 'Free QTY',
        value: 'Value',
        valueUsd: '(USD)',
      },
      modalHeaders: {
        qty: 'Total QTY',
        unitcost: 'Unit Cost',
        qStatus: 'Q-Status',
        risk: 'Risk',
      },
    },
    rfq: {
      title: 'RFQ',
      titleMenu: 'RFQ Overview',
      titleTabs: {
        open: 'Open',
        concluded: 'Concluded',
      },
      viewData: 'View Data',
      labels: {
        addNew: 'Add New',
        bidName: 'Bid Name',
        category: 'Category',
        suppliers: 'Suppliers',
        supplier: 'Supplier',
        start: 'Start',
        end: 'End',
        status: 'Status',
        actions: 'Actions',
      },
      placeholders: {
        bidName: 'Add BID Name',
        category: 'Add Category',
        supplier: 'Add Supplier',
        status: 'Select Status',
      },
    },
    newRfq: {
      title: 'New RFQ',
      steps: {
        registerBid: {
          title: 'Register BID',
          description: 'Provide the RFQ details',
          fields: {
            nameBID: 'Name BID',
            nameBIDMinCharacter: 'Minimum 2 characters',
            nameBIDMaxCharacter: 'Maximum 70 characters',
            placeholderNameBID: 'Define name for the BID',
            description: 'Description',
            placeholderDescriptionBID: 'Add a description about the BID',
            BIDPeriod: 'BID Period',
            startBIDPeriodPlaceholder: 'Start date',
            startBIDPeriodError: 'The start date cannot be less than the current date.',
            endBIDPeriodPlaceholder: 'End date',
            priceInterval: 'Price Interval',
            priceIntervalTooltip: 'Set the minimum price variation interval that can be submitted',
            priceIntervalError: 'The price interval must contain two decimal places',
          },
        },
        registerComponents: {
          title: 'Register Components',
          description: 'Add the required components',
          addComponent: 'Add Component',
          compressedComponent: {
            edit: 'Edit',
            delete: 'Delete',
            suppliers: 'Suppliers',
            demandPeriod: 'Demand Period',
            startingPrice: 'Starting Price',
            totalDemand: 'Total Demand',
          },
          fields: {
            altGroup: 'Alt Group',
            altGroupMinCharacter: 'Minimum 2 characters',
            altGroupMaxCharacter: 'Maximum 25 characters',
            placeholderAltGroup: 'Select Alt Group',
            category: 'Category',
            placeholderCategory: 'Select Category',
            specification: 'Specification',
            placeholderSpecification: 'Select Specification',
            projects: 'Projects',
            project: 'Project',
            cm: 'CM',
            all: 'All',
            selected: 'Selected',
            searchPlaceholder: 'Search here',
            qtd: 'Items',
            partNumbers: 'Part Numbers',
            parNumber: 'Part Number',
            qStatus: 'Q-Status',
            supplier: 'Supplier',
            demandPeriod: 'Demand Period',
            startDatePlaceholder: 'Start Date',
            endDatePlaceholder: 'End Date',
            from: 'From',
            to: 'To',
            startingPrice: 'Starting Price',
            priceError: 'Price must contain two decimal places',
            placeholderStartingPrice: 'Add Price',
            fobPrice: 'FOB Price',
            fobPriceTooltip: 'Current FOB price for imported items in this group',
            lastFOBPrice: 'Last FOB price',
            lastFOBPriceTooltip: 'Last FOB price for imported items in this group',
          },
        },
        addQuantities: {
          title: 'Add Quantities',
          description: 'Specify component quantities',
          deleteItemMessage: 'Do you want to delete this project?',
          label: {
            from: 'FROM',
            to: 'TO',
            periodDemand: 'DEMAND PERIOD',
            startingPrice: 'STARTING PRICE',
          },
          table: {
            project: 'Project',
            specification: 'Specification',
            demand: 'Demand',
          },
        },
        summarySupplier: {
          title: 'Summary Supplier',
          description: 'Confirm data for supplier submission',
          compressedComponent: {
            edit: 'Edit',
            delete: 'Delete',
            totalDemand: 'TOTAL DEMAND',
            components: 'COMPONENTS',
          },
          fields: {
            categories: 'CATEGORIES',
            specification: 'SPECIFICATION',
            components: 'Components',
            totalDemand: 'Total demand',
            selectCategory: 'Category',
            selectCategoryPlaceholder: 'Add Category',
            selectSpecification: 'Specification',
            selectSpecificationPlaceholder: 'Add Specification',
            table: {
              project: 'Project',
              cm: 'CM',
              altGroup: 'Alt Group',
              specification: 'Specification',
              partNumber: 'Part Number',
              qStatus: 'Q-Status',
              from: 'From',
              to: 'To',
              startingPrice: 'Starting Price',
              demand: 'Demand',
            },
          },
          removePartNumberMessage:
            'Do you want to delete this item? All data in the group will be removed',
          removeSupplierMessage:
            'Do yout want to delete this suplly? All data in the group will be removed',
        },
        send: {
          title: 'Send',
          description: 'Send RFQ to Suppliers',
          cardSubtitle: 'Invited to the BID',
          contactName: 'Contact Name',
          email: 'E-mail',
          message: 'Message',
          messageRequired: 'Message is required',
          invitedRequired: 'At least one supplier must be invited',
          saveMessageSucess:
            "Your message and contact information have been successfully saved. They will be sent to the supplier once you click 'Send'",
          invitedLimit: 'You can only invite up to 5 suppliers',
          modal: {
            title: 'Submission Confirmation',
            congratulations: 'Congratulations! You have successfully created your BID.',
            selectedItens:
              'The selected items have been sent to the suppliers. You will be notified once the quotations are added.',
          },
        },
        termsAndConditionsStep: {
          title: 'Terms and Conditions',
          description: 'This is a description',
          termsPlaceholder: 'Write the rules for the terms and conditions ...',
        },
      },
      deleteCategoryModalTitle: 'Delete Category',
      deleteCategoryModalDescription:
        'Do you want to delete this category? All data in the group will be removed.',
      exitModalTitle: 'Exit RFQ Registration',
      exitModalDescription:
        'You are about to leave your RFQ registration. Would you like to save your progress as a draft or delete all information?',
      saveDraft: 'Save as Draft',
      deleteAll: 'Delete',
    },
    bidResponse: {
      title: 'Respond to BID',
      subTitle: 'Please fill in the necessary information to submit your proposal.',
      labels: {
        project: 'PROJECT:',
        spec: 'SPECIFICATION:',
        partNumber: 'PART NUMBER:',
        from: 'FROM',
        to: 'TO',
        startPrice: 'STARTING PRICE',
        demandPeriod: 'DEMAND PERIOD',
        totalDemand: 'TOTAL DEMAND',
        demand: 'TOTAL DEMAND',
        decline: 'Decline participation in this Bid',
        gross: 'Gross Price',
        capacity: 'Capacity',
        leadTime: 'Lead Time',
        comments: 'Comments',
      },
      buttons: {
        place: 'Place the Bid',
        details: 'See more details',
        updateBid: 'Update the bid',
        saveUpdate: 'Save Update',
      },
      hints: {
        gross: 'Enter the gross price (including taxes, excluding IPI) ',
        capacity: "The company's ability to meet the demand for the group",
        leadTime: 'Time required to acquire and deliver components',
      },
      messages: {
        titleDeclined: 'Participation Declined',
        subTitleDeclined:
          'You have declined participation in this BID and therefore do not have a position in the ranking.',
        titleFirstPlace: 'Congratulations on 1st Place!',
        subTitleFirstPlace:
          'Your BID is currently in 1st place in the ranking. Keep up the great work!',
        titleSecondPlace: 'Almost There!',
        subTitleSecondPLace:
          'Great job! Your BID is currently in 2nd place in the ranking. Lower your prices to reach 1st place!',
        titleThirdPlace: 'Climb the Rankings!',
        subTitleThirdPlace:
          'Good job! Your BID is currently in 3rd place in the ranking. Lower your prices to move up even higher!',
        titleOtherPlaces: 'Your proposal can be more competitive',
        subTitleOtherPlaces:
          'To increase your chances of winning this BID, we suggest reviewing and adjusting your prices. You are not among the most competitive bids at the moment.',
        rankingForbid: 'Ranking for this Bid',
        saveSuccess:
          'Bid saved successfully. You will only send it to Acer when you click "Place the BID.',

        validatePriceInterval:
          'Invalid value! Use values lower than your last offer at least ​​of {{value}} less',
        usingAttempts: 'You are using your {{currentAttempt}} of {{totalAttempts}} attempts',
        usingAllAttempts:
          'You have used all allowed attempts {{currentAttempt}} of {{totalAttempts}}',
      },
      modalColumnLabels: {
        title: 'Breakdown per project',
        project: 'Project',
        cm: 'CM',
        specification: 'Specification',
        qStatus: 'Q-Status',
        partNumber: 'Part Number',
        demand: 'Demand',
      },
      altImages: {
        firstPlace: 'First place image',
        secondPlace: 'Second place image',
        thirdPlace: 'Third place image',
        exclamationBlue: 'Exclamation blue',
        fourthPlace: 'Image outside the podium',
      },
      popConfirm: {
        title: 'Request update',
        messageTitle: `You are updating the data for this group.`,
        messageBody: 'Click "Confirm" to send the updated data to Acer.',
        messageContinue: 'Do you want to continue?',
      },
    },
    viewRfq: {
      backButton: 'RFQ Overview',
      title: 'BID Summary',
      tabs: {
        overview: 'Overview',
        items: 'Items',
      },
      summary: {
        title: 'BID Register Information',
        supplierParticiation: 'SUPPLIER PARTICIPATION',
        componentsQuoted: 'COMPONENTS QUOTED',
        timeToCloseTheBid: 'TIME TO CLOSE THE BID',
        createdBy: 'CREATED BY',
        nameBid: 'NAME BID',
        createdOn: 'CREATED ON',
        description: 'DESCRIPTION',
        bidPeriod: 'BID PERIOD',
      },
      steps: {
        openBid: 'Open Bid',
        roundOpen: 'Round Open',
        receiveQuotes: 'Receive Quotes',
        bidClosed: 'Bid Closed',
        inReview: 'In Review',
        result: 'Result',
      },
      components: {
        cm: 'CM',
        cmPlaceholder: 'Select CM',
        project: 'Project',
        projectPlaceholder: 'Add Project',
        specification: 'Specification',
        specificationPlaceholder: 'Add Specification',
        supplier: 'Supplier',
        supplierPlaceholder: 'Add Supplier',
      },
      openCard: {
        specification: 'SPECIFICATION',
        cm: 'CM',
        from: 'FROM',
        to: 'TO',
        startingPrice: 'STARTING PRICE',
        totalDemand: 'TOTAL DEMAND',
        supplier: 'SUPPLIER',
        partNumber: 'PART NUMBER',
        qStatus: 'Q-STATUS',
        capacity: 'CAPACITY',
        leadTime: 'LEAD TIME',
        grossPrice: 'GROSS PRICE',
        lastGross: 'LAST GROSS',
        gapGross: 'GAP GROSS',
        netPrice: 'NET PRICE',
        totalNet: 'TOTAL NET',
        moreDetails: 'More Details',
        comments: 'Comments',
        award: 'Award',
        tags: {
          bestPrice: 'Best price',
          moreExpensive: 'More expensive',
        },
      },
    },

    bidSupplierRFQ: {
      Title: 'BID Summary',
      itemsTitle: 'Items for Quotation',
      editBid: 'Edit BID',
      roundDetails: 'Round Details',
      potentialRevenue: 'POTENTIAL REVENUE',
      grossPrice: 'GROSS PRICE',
    },

    awaitAuth: {
      title: 'You are being redirected to the',
      redirect: 'Redirect',
    },
    qualificationAlert: {
      title: 'Qualification Alerts',
      headerTitle: 'Q-status update from QG to {{data}}',
      found: 'found',
      fields: {
        project: 'Project',
        category: 'Category',
        specification: 'Specification',
        partNumber: 'Part Number',
        qSatus: 'Q-Status',
        remarks: 'Remarks',
      },
    },
  },
  components: {
    modals: {
      addNewTax: {
        title: 'Add New Tax',
        description: 'Tax Registration',
        partType: 'Category',
        taxesGroup: 'Taxes Group',
        selectComponentType: 'Select category',
        logistic: 'Logistic',
        periodRangePicker: 'Period',
        from: 'From',
        to: 'To',
        taxes: 'Taxes',
        recoveryTaxes: 'Recovery Taxes',
        ipi: 'IPI',
        icms: 'ICMS',
        pisConfins: 'PIS/CONFINS',
        rd: 'R&D',
        period: 'Choose the Period',
        formatTaxe: 'Rate with two decimal places',
        cancel: 'Cancelar',
        add: 'Add',
        save: 'Save',
      },
      uploadSheets: {
        title: 'Import a File',
        cancel: 'Cancel',
        upload: 'Upload',
        uploadSuccess: 'File Uploaded Successfully',
        uploadError: 'File must be Excel or CSV',
        labels: {
          listType: 'List Type',
          date: 'Date',
          responsible: 'Responsible',
        },
        placeholders: {
          CM: 'Select CM',
          listType: 'Select a Type',
          date: 'Select a Period',
          responsible: 'Responsible',
        },
        dragAndDrop: {
          title: 'Drag and drop files or click to add',
          subTitle: 'Only files .xls or .xlsx can be uploaded',
          fileError: 'You can only upload Excel file!',
          requiredFile: 'One file with xls or xlsx format is required!',
          maxFileSizeError: 'The maximum file size supported for file upload is {{maxSize}}',
        },
        listTypeOptions: {
          quotes: 'Quotes',
          componentsPrice: 'Imports Components Prices',
          localComponentsPrice: 'Local Components Prices',
          packingPrice: 'Packing Prices',
          average: 'Prices Average',
          demand: 'Demands',
          shareProportion: 'Share Proportion',
          inOut: 'In Out',
        },
        CmOption: 'Both',
      },
    },
    cards: {
      dollarExchange: {
        title: 'Dollar Exchange Rate',
        toolTipInformation: 'Updated information with BCB data',
        months: {
          jan: 'jan',
          feb: 'feb',
          mar: 'mar',
          apr: 'apr',
          may: 'may',
          jun: 'jun',
          jul: 'jul',
          aug: 'aug',
          sep: 'sep',
          oct: 'oct',
          nov: 'nov',
          dec: 'dec',
        },
      },
    },
    navigation: {
      Cost: 'Cost',
      Dashboards: 'Dashboards',
      Finance: 'Finance',
      FinanceDash: 'Finance View',
      ProcurementDash: 'Procurement View',
      DemandScenarios: 'Demands Scenarios',
      PricesScenarios: 'Prices Scenarios',
      ComponentsPrices: 'Components Prices',
      MaterialData: 'Material Data',
      Strategies: 'Strategies',
      Planning: 'Planning',
      Demand: 'Demands',
      Rfq: 'RFQ',
      SettingTaxes: 'Taxes',
      ppbRules: 'PPB Rules',
    },
    CardCountDown: {
      respond: 'Respond By',
      timeClose: 'Time to close the bid',
    },
  },
};
