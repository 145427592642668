import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import './translations/i18n';

Sentry.init({
  dsn: 'https://73e28c661e3fc6d813cd5f772c8e6de2@o4504084487340033.ingest.sentry.io/4506552586141696',
  tracePropagationTargets: ['acer-compass'],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 0.25, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event) {
    const isLocalhost = window.location.hostname === 'localhost';
    if (isLocalhost) {
      return null;
    }
    return event;
  },
});

/********************
 * Ugly fix needed: https://github.com/ant-design/ant-design/issues/26136
 * Suppressing Antd findDOMNode error on console because it doesn't fully support React Strict Mode
 * TODO: it should be removed when ANTD adds proper support for it
 ********************/

const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
  // console.log('args=>', args);
  // console.log('errObj=>', errObj);

  if (import.meta.env.DEV && (typeof errObj === 'string' || typeof errObj.message === 'string')) {
    if (args.includes('findDOMNode')) return;

    //hide Ant Design deprecated messages temporarily
    if (errObj.includes('Warning: `columns.render` return cell')) return;
    if (errObj.includes('Warning: [antd: Dropdown] `overlay` is deprecated.')) return;
    if (errObj.includes('Warning: [antd: Dropdown] `visible` is deprecated')) return;
    if (errObj.includes('Warning: [antd: Dropdown] `onVisibleChange` is deprecated')) return;
    if (errObj.includes('Warning: [antd: Menu] `children` will be removed')) return;
    if (errObj.includes('Warning: [antd: Tag] `visible` will be removed')) return;
    if (errObj.includes('Warning: [antd: Modal] `visible` will be removed')) return;
    if (errObj.includes('Warning: Instance created by `useForm` is not connected')) return;
  }
  consoleError(errObj, ...args);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
